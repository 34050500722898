<template>
  <div>
    <div>
      <div>
        <el-input
            style="width: 200px;margin-right: 20px"
            placeholder="角色登录名"
            v-model="searchRole.username"
            clearable
            @keydown.enter.native="searchRole.currentPage = 1; searchRole.size = 8; initRoles()"
        ></el-input>
        <el-input
            style="width: 200px;margin-right: 20px"
            placeholder="角色名称"
            v-model="searchRole.realname"
            clearable
            @keydown.enter.native="searchRole.currentPage = 1; searchRole.size = 8; initRoles()"
        ></el-input>
        <el-select
            v-model="searchRole.sex"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色性别"
            filterable
            clearable
            @change="searchRole.currentPage = 1; searchRole.size = 8; initRoles()"
        >
          <el-option
              v-for="item in sexs"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchRole.levelId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="角色等级"
            filterable
            clearable
            @change="searchRole.currentPage = 1; searchRole.size = 8; initRoles()"
        >
          <el-option
              v-for="item in levels"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <el-select
            v-model="searchRole.countryId"
            size="mini"
            @change="getAreasByCountry(searchRole.countryId)"
            style="width: 200px;margin-right: 20px"
            clearable
            filterable
            placeholder="所属国家"
        >
          <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
            v-model="searchRole.areaId"
            size="mini"
            style="width: 200px;margin-right: 20px"
            placeholder="所属地区"
            clearable
            filterable
            @change="searchRole.currentPage = 1; searchRole.size = 8; initRoles()"
        >
          <el-option
              v-for="item in areas"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-delete" type="warning" @click="resetData">
          重置
        </el-button>
        <el-button
            type="success"
            icon="el-icon-search"
            @click="searchRole.currentPage = 1;searchRole.size = 8 ;initRoles()"
        >
          搜索
        </el-button>
        <el-button
            type="success"
            icon="el-icon-download"
            @click="exportData"
        >
          导出数据
        </el-button>
        <el-button
            type="primary"
            icon="el-icon-plus"
            @click="showAddRoleView"
            v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1"
        >
          添加角色
        </el-button>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-table
          size="mini"
          :data="roles"
          border
          stripe
          :row-class-name="tableRowClassName"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table-column
            prop="username"
            label="角色登录名"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="realname"
            label="角色名称"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="sex"
            label="角色性别"
            width="100"
        >
        </el-table-column>
        <el-table-column
            prop="age"
            label="角色年龄"
            width="100"
        >
        </el-table-column>
        <el-table-column
            prop="level.name"
            label="角色等级"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="country.name"
            label="所属国家"
            width="150"
        >
        </el-table-column>
        <el-table-column
            prop="area.name"
            label="所属地区"
            width="150"
        >
        </el-table-column>
        <el-table-column
            label="角色头像"
            width="100"
        >
          <template #default="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="initUserFace(scope.row.userFace)"
                :preview-src-list="[initUserFace(scope.row.userFace)]"
            >
              <template #error>
                <div
                    class="image-slot"
                    style="text-align: center; line-height: 49px; border: 1px solid #ccc;"
                >
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="250"
            label="角色来源"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.source && scope.row.source.length <= 20" v-html="scope.row.source.replace(/\n/gm, '<br>')" slot="content">{{scope.row.source}}</span>
            <el-tooltip v-if="scope.row.source && scope.row.source.length > 20" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.source.replace(/\n/gm, '<br>')" slot="content">{{scope.row.source}}</span>
              <!--<span>{{scope.row.talent | ellipsis}}</span>-->
              <el-tag type="info">鼠标移动到上面查看</el-tag>
            </el-tooltip>
            <span v-if="!scope.row.source">-</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="角色描述"
        >
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.remark && toText(scope.row.remark).length > 15" class="item" effect="dark" placement="bottom">
              <span v-html="scope.row.remark.replace(/\n/gm, '<br>')" slot="content">{{scope.row.remark}}</span>
              <span v-if="scope.row.remark" v-html="$options.filters.ellipsis(scope.row.remark)"></span>
            </el-tooltip>
            <span v-else-if="scope.row.remark" v-html="toText(scope.row.remark)" slot="content"></span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="100"
            fixed="right"
        >
          <template slot-scope="scope">
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1" size="mini" style="padding: 3px" type="primary" @click="showUpdateRoleView(scope.row)">编辑</el-button>
            <el-button v-if="roleButton.buttonLevelComponents !== null && roleButton.buttonLevelComponents.indexOf('Role') !== -1" size="mini" style="padding: 3px" type="danger" @click="deleteRole(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: flex-end; margin-top: 5px">
        <el-pagination
            background
            layout="sizes, prev, pager, next, jumper, ->, total, slot"
            :page-sizes="[5, 8, 20, 40, 50, 100]"
            :current-page="searchRole.currentPage"
            :page-size="searchRole.size"
            :total="total"
            @current-change="currentChange"
            @size-change="sizeChange"
        >
        </el-pagination>
      </div>

      <el-dialog
          :close-on-click-modal="false"
          :title="title"
          :visible.sync="dialogVisible"
          @close="closeDialog()"
          width="800px">
        <el-form ref="roleForm" :model="role" :rules="rules">
          <table>
            <tr>
              <el-form-item label="角色登录名" prop="username" class="quantity-limit">
                <el-input
                    size="mini"
                    style="width: 200px"
                    v-model="role.username"
                    placeholder="请输入角色登录名"
                    maxlength="16"
                ></el-input>
                <span style="position: absolute; right: 10px; bottom: 0;">{{ role.username ? role.username.length : 0 }}/16</span>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色密码" prop="password">
                <el-input
                    size="mini"
                    type="password"
                    style="width: 200px"
                    v-model="role.password"
                    :disabled="disabled"
                    placeholder="请输入角色密码"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色名称" prop="realname">
                <el-input
                    size="mini"
                    style="width: 200px"
                    v-model="role.realname"
                    placeholder="请输入角色名称"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色性别" prop="sex">
                <el-select
                    v-model="role.sex"
                    size="mini"
                    style="width: 200px;"
                    placeholder="角色性别"
                    clearable
                    filterable
                >
                  <el-option
                      v-for="item in sexs"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色年龄">
                <el-input-number
                    size="mini"
                    style="width: 200px"
                    v-model="role.age"
                    placeholder="请输入角色年龄"
                ></el-input-number>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色等级" prop="levelId">
                <el-select
                    v-model="role.levelId"
                    size="mini"
                    style="width: 200px;"
                    placeholder="角色等级"
                    clearable
                    filterable
                >
                  <el-option
                      v-for="item in levels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="按钮权限">
                <el-select
                    v-model="role.buttonLevels"
                    size="mini"
                    multiple
                    style="width: 200px;"
                    placeholder="按钮权限"
                    clearable
                    filterable
                >
                  <el-option
                      v-for="item in buttonLevels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="所属国家" prop="countryId">
                <el-select
                    v-model="role.countryId"
                    @change="getAreasByCountry(role.countryId)"
                    size="mini"
                    style="width: 200px;"
                    placeholder="所属国家"
                    clearable
                    filterable
                >
                  <el-option
                      v-for="item in countries"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="所属地区" prop="areaId">
                <el-select
                    v-model="role.areaId"
                    size="mini"
                    style="width: 200px;"
                    placeholder="所属地区"
                    clearable
                    filterable
                >
                  <el-option
                      v-for="item in areas"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色头像">
                <el-upload
                    ref="upload"
                    :show-file-list="false"
                    :action="filesUploadUrl"
                    :on-success="filesUploadSuccess"
                >
                  <el-button type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <div style="margin-left: 130px">
                <el-image
                    v-if="role.userFace !== undefined && role.userFace !== null && role.userFace !== ''"
                    style="width: 100px; height: 100px; margin-top: 20px"
                    :src="initUserFace(role.userFace)"
                    :preview-src-list="[initUserFace(role.userFace)]"
                ></el-image>
              </div>
            </tr>
            <tr>
              <el-form-item label="角色来源" prop="source">
                <el-input
                    size="mini"
                    style="width: 200px"
                    v-model="role.source"
                    placeholder="请输入角色来源"
                ></el-input>
              </el-form-item>
            </tr>
            <tr>
              <el-form-item label="角色描述">
                <div id="richText"></div>
              </el-form-item>
            </tr>
          </table>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doRole">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {
  getAllLevels, getAllButtonTypeLevels, getAllCountries, getAreasByCountry,
  getAllAreas, rolePageData, addRole, updateRole, deleteRole, exportRole
} from "@/api/xrg/role";

// wangeditor富文本
import E from "wangeditor"
let editor;

export default {
  name: "Role",
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.role.username === '') {
        callback(new Error('请输入角色登录名'));
      } else if (/[\u4E00-\u9FA5]/g.test(this.role.username)) {
        callback(new Error('不能输入汉字'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      roles: [],
      total: 0,
      searchRole: {
        username: "",
        realname: "",
        sex: "",
        levelId: null,
        countryId: null,
        areaId: null,
        currentPage: 1,
        size: 8
      },
      areas: [],
      levels: [],
      buttonLevels: [],
      countries: [],
      dialogVisible: false,
      title: "",
      disabled: false,
      role: {
        id: null,
        username: "",
        password: "",
        realname: "",
        sex: "",
        age: null,
        levelId: null,
        countryId: null,
        areaId: null,
        source: "",
        remark: "",
        userFace: "",
        buttonLevels: [],
      },
      rules: {
        username: [{validator: validateUsername, trigger: "blur"}],
        realname: [{required: true, message: "请输入角色名称", trigger: "blur"}],
        password: [{required: true, message: "请输入角色密码", trigger: "blur"}],
        sex: [{required: true, message: "请选择性别", trigger: "change"}],
        levelId: [{required: true, message: "请选择角色等级", trigger: "change"}],
        countryId: [{required: true, message: "请选择所属国家", trigger: "change"}],
        areaId: [{required: true, message: "请选择所属地区", trigger: "change"}],
      },
      filesUploadUrl: window.server.filesUploadUrl + "/files/upload",
      sexs: store.getters.sexs,
      roleButton: store.getters.currentRole
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) {
        return '';
      }
      let input = value.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
      if (input.length > 15) {
        return input.slice(0, 15) + '...';
      } else {
        return value;
      }
    }
  },
  created() {
    // 富文本 解决页面切换失效问题
    editor = ''
  },
  computed: {

  },
  async mounted() {
    this.initLevels();
    this.initButtonLevels();
    this.initCountries();

    // 接收参数
    let query = this.$route.query;
    if (query.countryId) {
      this.searchRole.countryId = parseInt(query.countryId);
      this.getAreasByCountry(this.searchRole.countryId);
    }
    this.initRoles();
  },
  methods: {
    initRoles() {
      this.loading = true;
      rolePageData(this.searchRole).then(resp => {
        this.loading = false;
        if (resp) {
          this.total = resp.total;
          this.roles = resp.data;
        }
      })
    },
    toText(HTML) {
      let input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    },
    currentChange(page) {
      this.searchRole.currentPage = page;
      this.initRoles();
    },
    sizeChange(size) {
      this.searchRole.size = size;
      this.initRoles();
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
    initAreas() {
      getAllAreas().then(resp => {
        if (resp) {
          this.areas = resp;
        }
      })
    },
    initLevels() {
      getAllLevels().then(resp => {
        if (resp) {
          this.levels = resp;
        }
      })
    },
    initButtonLevels() {
      getAllButtonTypeLevels().then(resp => {
        if (resp) {
          this.buttonLevels = resp;
        }
      })
    },
    initCountries() {
      getAllCountries().then(resp => {
        if (resp) {
          this.countries = resp;
        }
      })
    },
    deleteRole(data) {
      this.$confirm(
          "此操作将永久删除[" + data.username + "]角色, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        deleteRole(data.id).then(resp => {
              if (resp) {
                this.initRoles();
              }
            }
        );
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    showAddRoleView() {
      this.title = "添加角色";
      this.disabled = false;
      this.areas = [];
      this.role = {
        id: null,
        username: "",
        password: "",
        realname: "",
        sex: "",
        age: null,
        levelId: null,
        countryId: null,
        areaId: null,
        source: "",
        remark: "",
        userFace: "",
        buttonLevels: [],
      };
      this.dialogVisible = true;
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 清空内容
        editor.txt.html('')
      })
    },
    showUpdateRoleView(data) {
      this.getAreasByCountry(data.countryId);
      // 拷贝
      this.role = JSON.parse(JSON.stringify(data));
      this.title = "编辑角色";
      // 禁止修改密码
      this.disabled = true;
      this.dialogVisible = true;
      // 渲染富文本
      this.$nextTick(() => {
        if (!editor) {
          editor = new E("#richText");
          editor.config.height = 300;
          // 配置上传图片地址
          editor.config.uploadImgServer = window.server.filesUploadUrl + "/files/rich/upload";
          // 自定义fileName
          editor.config.uploadFileName = "file";
          editor.create();
        }
        // 内容赋值
        editor.txt.html(this.role.remark)
      })
    },
    doRole() {
      if (this.role.id) {
        // 编辑
        this.$refs["roleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.role.remark = editor.txt.html();
            updateRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initRoles();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        // 添加
        this.$refs["roleForm"].validate((valid) => {
          if (valid) {
            // 富文本需要手动赋值
            this.role.remark = editor.txt.html();
            addRole(this.role).then(resp => {
              if (resp) {
                this.dialogVisible = false;
                this.initRoles();
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    getAreasByCountry(countryId) {
      this.role.areaId = null;
      this.searchRole.areaId = null;
      getAreasByCountry(countryId).then(resp => {
        if (resp) {
          this.areas = resp;
        }
      });
      this.searchRole.currentPage = 1;
      this.searchRole.size = 8;
      this.initRoles();
    },
    exportData() {
      exportRole();
    },
    resetData() {
      this.searchRole.username = "";
      this.searchRole.realname = "";
      this.searchRole.sex = "";
      this.searchRole.levelId = null;
      this.searchRole.countryId = null;
      this.searchRole.areaId = null;
      this.areas = [];
    },
    filesUploadSuccess(res) {
      this.role.userFace = res.data;
    },
    closeDialog() {
      this.areas = [];
      if (this.$refs["upload"]) {
        this.$refs["upload"].clearFiles(); // 清除历史文件列表
      }
      this.$refs["roleForm"].resetFields();
    },
    initUserFace(image) {
      return window.server.apiBase + "/files?filePath=" + image + "&name=";
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

label {
  display: inline-block;
  width: 100px;
  text-align: right;
}
</style>
